import React from "react";
import { Container } from "react-bootstrap";
import GridLayout from "../../Layouts/GridLayout";
import ButtonSection from "./ButtonSection";
import "./HeroSection.scss";

const HeroSection = () => {
  return (
    <GridLayout squarePosition="square-position-1">
      <Container>
        <div className=" d-flex justify-content-center hero-wrapper">
          <div>
            <div className=" hero-wrapper-responsive">
              <div className=" d-flex flex-column align-items-center hero-text">
                <div className="d-block">Darwin</div>
                <div className="d-block">(Bio + AI)</div>
              </div>
              <div className="d-flex justify-content-center">
                <p className=" mt-lg-5 pt-5  text-center sub-text ">
                An Onco Semantics Lab on a mission to decode cancer’s language 
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <p className="sub-content-text">
              Drug resistance remains a formidable challenge in cancer treatment, with a range of implications such as variable drug efficacy across patient populations (due to insufficient target validation during drug discovery), unexpected side effects, inconsistent therapeutic outcomes, and increased economic burdens for pharmaceutical companies, hospitals, and patients.

              </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <p className="sub2-content-text pt-0">
                <b>OncoGalactica :</b> To investigate the molecular causes of drug resistance and tumour recurrence in solid tumour conditions, Darwin (Bio + AI) has been actively developing OncoGalactica, its flagship Onco Semantic framework;

              </p>
              <p className="sub2-content-text ">
              1. Integrate molecular and clinical oncology data with AI semantics for mechanistic and probabilistic inferences

              </p>
              <p className="sub2-content-text">
              2. Oncology-specific ML models for dynamic protein modelling and protein-protein and protein-ligand interaction modelling 

              </p>
              <p className="sub2-content-text">
              3. Decipher causal relationships between receptors and downstream signalling proteins, and cross-talk between signalling pathways 

              </p>
              <p className="sub2-content-text">
              4. Reconstruct unique molecular aetiology profiles for individual cancer patients
              </p>

              <p className="sub2-content-text" style={{ marginBottom: "40px" }}>
              5. Identify key/actionable molecular drivers of drug resistance and tumour recurrence with patient-level granularity
              </p>
            </div>

            <div className="d-flex justify-content-center">
              <p className="sub-content-text">
                <b>Our  Approach :</b> Dynamic protein modelling presents a key challenge in identifying the molecular drivers of drug resistance in cancer. Simulating the dynamic conformational states of a protein (with thousands of atoms) requires resolving atomic kinetics across multiple scales, from femtosecond-level bond fluctuations to larger conformational shifts over longer time intervals. To address this complexity, Darwin (Bio + AI) leverages time-stamp dynamic modelling techniques and AI-based ensemble approaches to study protein variants, their dynamic confirmations, and their interaction networks under simulated multi-ligand environments that include ligands, macromolecules, and ions, enabling a deeper understanding of resistance mechanisms and drug binding affinities.
              </p>
            </div>

            <div className="d-flex justify-content-center">
              <p className="sub-content-text">
                <b>Privacy Notice :</b> We prioritise user privacy and
                anonymity. Therefore, our digital assets do not utilise cookies
                or user behaviour-tracking tools.
              </p>
            </div>

            <ButtonSection />
          </div>
        </div>
      </Container>
    </GridLayout>
  );
};

export default HeroSection;
